var render, staticRenderFns
import script from "./WeightChart.vue?vue&type=script&lang=js&"
export * from "./WeightChart.vue?vue&type=script&lang=js&"
import style0 from "./WeightChart.vue?vue&type=style&index=0&id=0e7f39a1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e7f39a1",
  null
  
)

/* custom blocks */
import block0 from "./WeightChart.vue?vue&type=custom&index=0&blockType=div"
if (typeof block0 === 'function') block0(component)

export default component.exports