<div>
  <canvas ref="weightChart"></canvas>
</div>

<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['options'],
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  methods: {
    renderLineChart() {
      this.$data._chart.destroy()
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>
<style scoped>
#line-chart{
	padding: 20px!important;
}
</style>
