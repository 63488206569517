<template>
<!-- eslint-disable -->
	<div class="mt-4">
		<h1>体重</h1>
		<v-card>
			<div class="my-4">
				<weight-chart ref="weightChart" :chart-data="chartData" :options="chartOptions"/>
			</div>
		</v-card>
		<v-btn class="mt-12 mx-auto px-5" style="display: block;" type="submit" color="primary" @click="addWeight()"><v-icon size=20 class="pl-2">mdi-pencil</v-icon>記録する</v-btn>
	</div>
</template>

<script>
/*eslint-disable */
import * as api from '@/api.js'
import WeightChart from '@/components/WeightChart.vue';
export default {
	name: 'Home',
	components: { WeightChart },
	data () {
		return {
			weightList: [],
			weight: 60.0,
            chartData: {
                labels: [],
                datasets: [
					{
						label: '',
						data: [],
						backgroundColor: '',
						borderColor: 'rgba(75, 192, 192, 1)',
						borderWidth: 1,
					},
                ],
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
					y: {
						beginAtZero: true,
					},
                },
				legend: {
					display: false,
				},
            }
		}
	},
	methods: {
		load() {
			api.home.load({userId: this.$store.getters.loginInfo.userId}).then((res) => {
                switch(res.status) {
                    case 200:
						this.weightList = res.data
						this.setData()
						this.updateChart()
                        break
                    case 500:
						this.errorFlg = true
						this.pop(res.data.error[0])
                        break
                }
			}).finally()
		},
		setData(){
			let newData = []
			let scales = []
			let keys = Object.keys(this.weightList)
			for(let i=keys.length - 5; i < keys.length; i++){
				scales.push(this.changeFormat(keys[i]))
				this.weight = this.weightList[keys[i]]
				newData.push(this.weight)
			}
			this.chartData.labels = scales
			this.$set(this.chartData.datasets[0], 'data', newData)
			this.$store.dispatch('setWeight', { weight: this.weight })
		},
		changeFormat(dateTime){
			let date = new Date(dateTime);
			let month = String(date.getMonth() + 1).padStart(2, "0");
			let day = String(date.getDate()).padStart(2, "0");
			let formattedDate = `${month}/${day}`;
			return formattedDate;
		},
		updateChart() {
			this.$refs.weightChart.renderLineChart()
		},
		addWeight(){
			this.$router.push({ name: 'Weight' })
		},
	},
	created(){
		this.chartData.labels = this.dates
		this.load()
	},
	computed: {
	}
}
</script>